<template>
  <div>
    <b-card v-if="client">
      <table class="table table-sm table-borderless">
        <tbody>
          <tr>
            <th>Client</th>
            <td colspan="2">
              {{ client.displayName }}
            </td>
          </tr>
          <tr>
            <th>Status</th>
            <td colspan="2">
              {{ client.enabled ? 'Enabled' : 'Disabled' }}
            </td>
          </tr>
          <tr>
            <th>Agent Module</th>
            <td colspan="2">
              {{ client.agentModuleId }}
            </td>
          </tr>
          <tr>
            <th>Created on</th>
            <td colspan="2">
              {{ client.createdOn | formatDateTime }}
            </td>
          </tr>
          <tr>
            <th>Full Schedule CRON</th>
            <td colspan="2">
              {{ client.fullScheduleCron }}
            </td>
          </tr>
          <tr v-if="client.partialScheduleCron">
            <th>Partial Schedule CRON</th>
            <td colspan="2">
              {{ client.partialScheduleCron }}
            </td>
          </tr>
          <tr>
            <td colspan="3">
              <hr>
            </td>
          </tr>
          <tr>
            <th>Last Collection On</th>
            <td colspan="2">
              {{ client.lastCollectionOn | formatDateTime }}
            </td>
          </tr>
          <tr>
            <th>Last Collection Status</th>
            <td colspan="2">
              <b-badge :variant="client.lastCollectionStatus === 0 ? 'success' : 'danger'">
                {{ client.lastCollectionStatus === 0 ? 'OK' : 'Error' }}
              </b-badge>
            </td>
          </tr>
          <tr v-if="client.lastMessage">
            <th>Last Message</th>
            <td colspan="2">
              {{ client.lastMessage }}
            </td>
          </tr>
          <tr>
            <td colspan="3">
              <hr>
            </td>
          </tr>
          <tr>
            <th>Last Connection Test On</th>
            <td colspan="2">
              {{ client.lastTestOn | formatDateTime }}
            </td>
          </tr>
          <tr>
            <th>Last Connection Test Status</th>
            <td colspan="2">
              <b-badge :variant="client.lastTestSucceeded ? 'success' : 'danger'">
                {{ client.lastTestSucceeded ? 'OK' : 'Error' }}
              </b-badge>
            </td>
          </tr>
          <tr v-if="client.lastTestErrorMessage">
            <th>Last Connection Test Message</th>
            <td colspan="2">
              {{ client.lastTestErrorMessage }}
            </td>
          </tr>
        </tbody>
      </table>
    </b-card>

    <b-card title="Collected data">
      <table class="table">
        <thead>
          <tr>
            <th>Status</th>
            <th>Mode</th>
            <th>Started On</th>
            <th>Duration</th>
            <th>Data Size</th>
            <th>Process Mode</th>
            <th>Is Processed</th>
            <th>Process Errors</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="data in collectedData"
            :key="data.id"
          >
            <td v-if="data.collectionStatus === 0">
              <b-badge variant="success">
                OK
              </b-badge>
            </td>
            <td v-if="data.collectionStatus !== 0">
              <b-badge variant="danger">
                Error
              </b-badge>
              <feather-icon
                v-if="data.message"
                v-b-popover.hover="`${data.message}`"
                class="ml-1"
                icon="HelpCircleIcon"
              />
            </td>
            <td>{{ data.isPartial ? "Partial" : "Full" }}</td>
            <td>{{ data.collectionStartedOn | formatDateTime }}</td>
            <td>{{ Math.round($moment.duration($moment.utc(data.collectionEndedOn) - $moment.utc(data.collectionStartedOn)).asSeconds(), 0) }}s</td>
            <td>{{ data.preparedDataLength | prettyBytes }}</td>
            <td>{{ data.processMode | agentProcessorMode }}</td>
            <td>
              {{ data.isProcessed ? "Yes" : "No" }}
            </td>
            <td>

              <b-badge :variant="data.hasProcessError ? 'danger' : 'success'">
                {{ data.hasProcessError ? 'Process error' : 'OK' }}

                <feather-icon
                  v-if="data.hasProcessError && data.lastProcessError"
                  v-b-popover.hover="`${data.lastProcessError}`"
                  class="ml-1"
                  icon="HelpCircleIcon"
                />
              </b-badge>

            </td>
          </tr>
        </tbody>
      </table>
    </b-card>
  </div>
</template>

<script>

import {
  BCard, BBadge, VBPopover,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    BCard,
    BBadge,
  },
  directives: {
    'b-popover': VBPopover,
  },
  props: {
    asup: {
      type: Object,
      default: () => {},
    },
    asset: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters({
      isCurrentUserHost: 'auth/isHost',
    }),
    clientId() {
      return parseInt(this.$route.params.clientId, 10)
    },
    client() {
      if (this.clientId && this.asup && this.asup.details && this.asup.details.clients) {
        return this.asup.details.clients.find(client => client.id === this.clientId)
      }

      return null
    },
    collectedData() {
      if (this.client && this.client.collectedData) {
        return [...this.client.collectedData].sort((a, b) => new Date(b.collectionStartedOn) - new Date(a.collectionStartedOn))
      }

      return []
    },
  },
}
</script>
